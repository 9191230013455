import { pinyin } from 'pinyin-pro'
import ResponseCode from '../common/response-code'
import { notification } from 'antd'

const fileTypeMap = {
  'zip': 'application/zip',
  '7z': 'application/x-7z-compressed',
  'rar': 'application/x-rar-compressed',
  'pdf': 'application/pdf;charset=utf-8',
  'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; application/octet-stream',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'png': 'image/png',
  'jpg': 'image/jpeg',
  'gif': 'image/gif',
  'jpeg': 'image/jpeg'
}

export const replaceChineseInitials = (str) => {
  const reg = /[\u4e00-\u9fa5]/g

  return str.replace(reg, (chinese) => {
    return pinyin(chinese, { pattern: 'first' })
  })
}

export const getUid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export function sectionToChinese (num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
    alert('Number is wrong!')
    return 'Number is wrong!'
  }
  let AA = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  let BB = ['', '十', '百', '千', '万', '亿', '点', '']
  let a = ('' + num).replace(/(^0*)/g, '').split('.'),
    k = 0,
    re = ''
  for (let i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re
        break
      case 4:
        if (!new RegExp('0{4}\\d{' + (a[0].length - i - 1) + '}$').test(a[0]))
          re = BB[4] + re
        break
      case 8:
        re = BB[5] + re
        BB[7] = BB[5]
        k = 0
        break
    }
    if (k % 4 === 2 && a[0].charAt(i + 2) !== 0 && a[0].charAt(i + 1) === 0) re = AA[0] + re
    if (a[0].charAt(i) !== 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re
    k++
  }
  if (a.length > 1) {
    re += BB[6]
    for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)]
  }
  return re
}

export const downloadPdf = (data, fileName) => {
  try {
    if (data.code && data.code !== ResponseCode.success) {
      notification.open({
        type: 'warning',
        message: '错误',
        description: data.msg
      })
    } else {
      throw new Error('未接收到异常数据')
    }
  } catch (e) {
    let blob = new Blob([data], {
      type: 'application/pdf;charset=utf-8'
    })
    let objectUrl = URL.createObjectURL(blob)
    let a = document.createElement('a')
    a.href = objectUrl
    a.download = fileName
    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
    window.URL.revokeObjectURL(blob)
  }
}

export function blobToBase64 (blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      resolve(e.target.result)
    }
    // readAsDataURL
    fileReader.readAsDataURL(blob)
    fileReader.onerror = () => {
      reject(new Error('blobToBase64 error'))
    }
  })
}

const isJsonObject = (obj) => {
  try {
    JSON.parse(obj)
    return true
  } catch (error) {
    return false
  }
}

const preDownloadFile = (data) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      if (!isJsonObject(e.target.result)) {
        resolve(data)
      } else {
        reject(JSON.parse(e.target.result).msg)
      }
    }
    reader.readAsText(new Blob([data], {
      type: 'application/json'
    }))
  })
}

export const downloadFile = (data, fileType, fileName) => {
  return preDownloadFile(data).then((data) => {
    const blob = new Blob([data], {
      type: fileTypeMap[fileType]
    })
    const objectUrl = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = objectUrl
    a.download = `${fileName}.${fileType}`
    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
    // @ts-ignore
    window.URL.revokeObjectURL(blob)
  })
}

export const throttle = (method, delay = 100, duration) => {
  let timer = null
  let begin = new Date()
  return function () {
    const context = this
    const args = arguments
    const current = new Date()
    clearTimeout(timer)
    if (current - begin >= duration) {
      method.apply(context, args)
      begin = current
    } else {
      timer = setTimeout(function () {
        method.apply(context, args)
      }, delay)
    }
  }
}

export const deepClone = (obj) => {
  const newObj = {}
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]
      newObj[key] = typeof value === 'object' ? deepClone(value) : value
    }
  }
  return newObj
}
