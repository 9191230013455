import React, { useEffect, useRef, useState } from 'react'
import { Button, ConfigProvider, Modal, notification, Radio, Space, Spin, Table } from 'antd'
import { CheckSquareOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import {
  deleteApplyField,
  getApplyFormFields,
  getExamDictData
} from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import ConfigApplyFieldsModal from './ConfigApplyFieldsModal'
import { updateDictInfo } from '../../../../store/slice/dict-slice'
import EditApplyFormFieldModal from '../../../../components/EditApplyFormFieldModal'
import FieldSortModal from './FieldSortModal'
import SelectRequiredFieldsModal from './SelectRequiredFieldsModal'
import ComponentDisabled from '../../../../components/ComponentDisabled'
import ComponentVisibleByAuth from '../../../../components/ComponentVisibleByAuth'
import eventBus from '../../../../plugins/events'

function SettingApplyFields () {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const dispatch = useDispatch()
  const fieldSortModalRef = useRef()
  const editApplyFormFieldModalRef = useRef()
  const configApplyFieldsModalRef = useRef()
  const selectRequiredFieldsModalRef = useRef()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedTableItem, setSelectedTableItem] = useState([])
  const [fieldTypeOptions, setFieldTypeOptions] = useState([])
  const [selectedFieldType, setSelectedFieldType] = useState('ordinary')

  useEffect(() => {
    if (selectedExam.id) {
      getInitData()
    }
  }, [selectedExam])

  const columns = [
    {
      title: '字段名称',
      width: '300px',
      dataIndex: 'fieldName',
      key: 'fieldName'
    },
    {
      title: '字段编码',
      width: '180px',
      dataIndex: 'fieldCode',
      key: 'fieldCode'
    },
    {
      title: '字段类型',
      width: '220px',
      dataIndex: 'fieldType',
      key: 'fieldType',
      render: (record) => {
        return fieldTypeOptions?.find(item => {
          return item.dictValue === record
        })?.dictLabel
      }
    },
    {
      title: '是否必填',
      width: '120px',
      dataIndex: 'fieldIsNotBlank',
      key: 'fieldIsNotBlank',
      render: (record) => {
        return Number(record) === 1 ? '必填' : '非必填'
      }
    },
    {
      title: '说明',
      width: '300px',
      dataIndex: 'fieldExplain',
      key: 'fieldExplain'
    },
    {
      title: '操作',
      width: '150px',
      dataIndex: 'operation',
      key: 'operation',
      render: (data, row) => {
        return (<>
          <ComponentVisibleByAuth authCode={ 'button_ksxq-bmzdsz-bj' }>
            <Space className="d-flex justify-content-end">
              <Button
                type="primary"
                ghost
                onClick={ openModal.bind(this, row, showEditApplyFieldsModal) }
              >编辑</Button>
              <ComponentDisabled
                disabledTip={ '报名已开始，禁止修改' }
                disabled={ selectedExam.examinationStatus > 0 }
              >
                <Button
                  type="primary"
                  danger
                  ghost
                  onClick={ openModal.bind(this, row, showDeleteApplyFieldsModal) }
                >删除</Button>
              </ComponentDisabled>
            </Space>
          </ComponentVisibleByAuth>
        </>)
      }
    }
  ]

  const getInitData = () => {
    setLoading(true)
    Promise.all([
      getExamDictData({ dictCode: 'sys_fieldKind' }),
      getApplyFormFields({ examId: selectedExam.id })
    ]).then(([dictRes, fieldRes]) => {
      setLoading(false)
      if (dictRes.code === ResponseCode.success) {
        setFieldTypeOptions(dictRes.data)
        dispatch(updateDictInfo({ dictKey: 'sys_fieldKind', dictValue: dictRes.data }))
      } else {
        Modal.error({
          title: '错误',
          content: dictRes.msg
        })
      }

      if (fieldRes.code === ResponseCode.success && fieldRes.data) {
        let data = []
        if (fieldRes.data.applyFileFields) {
          data = data.concat(fieldRes.data.applyFileFields)
        }

        if (fieldRes.data.applyFormFields) {
          data = data.concat(fieldRes.data.applyFormFields)
        }

        setTableData(data)
      } else {
        Modal.error({
          title: '错误',
          content: fieldRes.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      Modal.error({
        title: '错误',
        content: err
      })
    })
  }

  const getTableData = () => {
    let data = []

    getApplyFormFields({ examId: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        if (res.data.applyFileFields) {
          data = data.concat(res.data.applyFileFields)
        }

        if (res.data.applyFormFields) {
          data = data.concat(res.data.applyFormFields)
        }

        setTableData(data)
        eventBus.emit('reloadTableData', selectedExam.id)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询考试报名字段失败'
      })
    })
  }

  const showEditApplyFieldsModal = () => {
    editApplyFormFieldModalRef.current.showModal()
  }

  const showDeleteApplyFieldsModal = (row) => {
    Modal.confirm({
      title: '删除报名字段',
      content: `删除报名字段【${ row.fieldName }】`,
      centered: true,
      onOk: () => {
        deleteApplyFieldAction([row])
      }
    })
  }

  const showDeleteAllApplyFieldsModal = () => {
    if (selectedTableItem.length > 0) {
      Modal.confirm({
        title: '批量删除报名字段',
        content: '确定要批量删除报名字段吗？',
        centered: true,
        onOk: () => {
          deleteApplyFieldAction(selectedTableItem)
        }
      })
    } else {
      Modal.error({
        title: '错误',
        content: '未选择报名字段，不能进行批量操作',
        centered: true
      })
    }
  }

  const deleteApplyFieldAction = (fieldItems) => {
    setLoading(true)
    const params = {
      examId: selectedExam.id,
      fieldsCodeList: fieldItems.filter(item => {
        return item.fieldType !== '11'
      }).map(item => item.fieldCode),
      fileFieldsCodeList: fieldItems.filter(item => {
        return item.fieldType === '11'
      }).map(item => item.fieldCode)
    }
    deleteApplyField(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })

        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '删除报名字段失败',
        centered: true
      })
    })
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showCreateExamApplyFieldsModal = () => {
    configApplyFieldsModalRef.current.showModal()
  }

  const showFieldsSortModal = () => {
    fieldSortModalRef.current.showModal()
  }

  const showSelectRequiredFiledModal = () => {
    selectRequiredFieldsModalRef.current.showModal()
  }

  const insertSuccess = () => {
    eventBus.emit('updateExamMonitor')
    getTableData()
  }

  const selectedFieldTypeChange = (e) => {
    setSelectedFieldType(e.target.value)
  }

  const getSelectedTableData = (selectedType) => {
    if (selectedType === 'accessory') {
      return tableData.filter(item => {
        return item.fieldType === '11'
      })
    } else {
      return tableData.filter(item => {
        return item.fieldType !== '11'
      })
    }
  }

  return (
    <div>
      { contextHolder }
      <div className="mb-2 d-flex align-items-center justify-content-between">
        <Radio.Group className={ 'mb-3' } value={ selectedFieldType } onChange={ selectedFieldTypeChange }>
          <Radio.Button value={ 'ordinary' }>普通字段</Radio.Button>
          <Radio.Button value={ 'accessory' }>附件</Radio.Button>
        </Radio.Group>
        <ComponentVisibleByAuth authCode={ 'button_ksxq-bmzdsz-bj' }>
          <Space>
            <Button
              type="primary"
              icon={ <PlusOutlined/> }
              onClick={ openModal.bind(this, {}, showCreateExamApplyFieldsModal) }
            >配置报名字段</Button>
            <Button
              type="primary"
              icon={ <SwapOutlined/> }
              onClick={ openModal.bind(this, {}, showFieldsSortModal) }
            >字段排序</Button>
            <Button
              type="primary"
              icon={ <CheckSquareOutlined/> }
              onClick={ openModal.bind(this, {}, showSelectRequiredFiledModal) }
            >设置必填字段</Button>
            <ComponentDisabled
              disabledTip={ '报名已开始，禁止修改' }
              disabled={ selectedExam.examinationStatus > 0 }
            >
              <Button
                type="primary"
                danger
                onClick={ openModal.bind(this, {}, showDeleteAllApplyFieldsModal) }
              >批量删除</Button>
            </ComponentDisabled>
          </Space>
        </ComponentVisibleByAuth>
      </div>

      <Spin spinning={ loading }>
        <ConfigProvider componentSize={ 'small' }>
          <Table
            loading={ loading }
            columns={ columns }
            dataSource={ getSelectedTableData(selectedFieldType) }
            rowSelection={ {
              type: 'checkbox',
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedTableItem(selectedRows)
              }
            } }
            rowKey={ (record) => record.fieldCode }
            pagination={ false }
          ></Table>
        </ConfigProvider>
      </Spin>

      <ConfigApplyFieldsModal
        ref={ configApplyFieldsModalRef }
        examInfo={ selectedExam }
        selectedFieldList={ tableData }
        insertSuccess={ insertSuccess }
      ></ConfigApplyFieldsModal>

      <EditApplyFormFieldModal
        ref={ editApplyFormFieldModalRef }
        fieldItem={ currentEditItem }
        examInfo={ selectedExam }
        modalType={ 'edit' }
        isExamField={ true }
        fieldLevel={ currentEditItem.fieldLevel }
        insertSuccess={ getTableData }
      ></EditApplyFormFieldModal>

      <FieldSortModal
        ref={ fieldSortModalRef }
        fieldList={ tableData }
        examInfo={ selectedExam }
        insertSuccess={ getTableData }
      ></FieldSortModal>

      <SelectRequiredFieldsModal
        ref={ selectRequiredFieldsModalRef }
        fieldList={ tableData }
        examInfo={ selectedExam }
        insertSuccess={ getTableData }
      ></SelectRequiredFieldsModal>
    </div>
  )
}

export default SettingApplyFields
